@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
	font-family: 'Epilogue'; /*Can be any text*/
	src: local('Epilogue'),
		url('../../fonts/Epilogue-Regular.ttf') format('truetype');
}
body {
	font-family: 'DM Sans', sans-serif;
}

option {
	color: black;
}

.react-text-editor {
	margin: 0px !important;
	border: 1px solid gainsboro !important;
}

.titleTips {
	display: -webkit-box;
	max-width: 400px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.descriptionTips {
	display: -webkit-box;
	max-width: 400px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}